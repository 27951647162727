<template>
  <v-app>
    <v-app-bar
      v-if="$route.path === '/wedding-registry'"
      app
      dark
      hide-on-scroll
      color="red darken-1"
    >
      <h2 class="mx-auto">Wedding Registry Creator</h2>
    </v-app-bar>

    <v-main class="background">
      <router-view />
    </v-main>

    <div
      hidden
      id="snipcart"
      :data-api-key="apiKey"
      :data-config-modal-style="snipcartConfig.modalStyle"
    >
      <payment section="top">
        <v-sheet class="mt-4 pa-1" border rounded color="red lighten-4">
          <h1 class="text-center black--text font-weight-bold subtitle-2">
            Non-transferable. Non-refundable. All sales final.
          </h1>
        </v-sheet>
      </payment>
    </div>

    <v-snackbar v-model="snack.value">
      {{ snack.message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red darken-1" v-bind="attrs" @click="snack.value = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { auth } from "../firebase";

export default {
  name: "App",

  metaInfo: {
    titleTemplate: "The Chapel at Dragonfly Farms — %s",

    link: [
      {
        rel: "preconnect",
        href: "https://app.snipcart.com",
      },
      {
        rel: "preconnect",
        href: "https://cdn.snipcart.com",
      },
      {
        rel: "stylesheet",
        href: "https://cdn.snipcart.com/themes/v3.2.0/default/snipcart.css",
      },
      {
        rel: "shortcut icon",
        href: "~/assets/favicon.ico",
      },
      {
        rel: "apple-touch-icon",
        sizes: "180x180",
        href: "~/assets/apple-touch-icon.png",
      },
      {
        rel: "icon",
        sizes: "32x32",
        type: "image/png",
        href: "~/assets/favicon-32x32.png",
      },
      {
        rel: "icon",
        sizes: "16x16",
        type: "image/png",
        href: "~/assets/favicon-16x16.png",
      },
    ],

    script: [
      {
        src: "https://cdn.snipcart.com/themes/v3.2.0/default/snipcart.js",
        async: true,
        defer: true,
      },
    ],
  },

  data: () => ({
    snipcartConfig: {
      modalStyle: "side",
    },
  }),

  computed: {
    ...mapState({
      snack: (state) => state.snack,
      user: (state) => state.user,
      partner1: (state) => state.partner1,
      partner2: (state) => state.partner2,
      page: (state) => state.page,
    }),

    apiKey() {
      let key = process.env.VUE_APP_SNIPCART_PUBLIC_TEST_KEY;

      if (process.env.VUE_APP_ENV === "stage") {
        key = process.env.VUE_APP_SNIPCART_PUBLIC_TEST_KEY;
      }

      if (process.env.VUE_APP_ENV === "prod") {
        key = process.env.VUE_APP_SNIPCART_PUBLIC_LIVE_KEY;
      }

      return key;
    },
  },

  created() {
    console.log(`Current Environment: ${process.env.VUE_APP_ENV}`);

    auth.onAuthStateChanged((user) => {
      this.$store.dispatch("setUser", user);
    });
  },
};
</script>


<style scoped>
.background {
  background: #1e1e1e;
}

.font {
  font-family: Elodie;
}
</style>