<template>
  <v-expansion-panels popout v-model="show">
    <v-expansion-panel>
      <v-expansion-panel-header
        v-slot
        :disabled="isValid"
        :expand-icon="isValid ? 'mdi-check' : 'mdi-chevron-down'"
      >
        <v-row no-gutters>
          <v-col class="left-text" cols="12">
            <h2>
              <v-icon color="black" large left> mdi-page-layout-body </v-icon>
              Couples Page
            </h2>
          </v-col>
        </v-row>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <v-form>
          <v-container fluid>
            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-text-field
                  hint="Note: This field is optional"
                  persistent-hint
                  solo
                  dark
                  v-model="page.title.value"
                  :label="page.title.label"
                  :error-messages="errors.page.title(page.title.label)"
                  :counter="200"
                  @input="$v.page.title.value.$touch"
                  @blur="$v.page.title.value.$touch"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      solo
                      dark
                      :value="dateFormat"
                      :label="page.date.label"
                      :error-messages="errors.page.date(page.date.label)"
                      @input="$v.page.date.value.$touch"
                      @blur="$v.page.date.value.$touch"
                      readonly
                      slot="activator"
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>

                  <v-date-picker
                    color="red darken-1"
                    v-model="page.date.value"
                    @input="menu = false"
                    required
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  hint="Note: This field is optional"
                  persistent-hint
                  solo
                  dark
                  v-model="page.description.value"
                  :label="page.description.label"
                  :error-messages="
                    errors.page.description(page.description.label)
                  "
                  :counter="450"
                  @input="$v.page.description.value.$touch"
                  @blur="$v.page.description.value.$touch"
                >
                </v-textarea>
              </v-col>

              <v-col v-show="preview.file" cols="12">
                <v-img
                  class="mx-auto rounded-lg elevation-10"
                  max-width="700px"
                  :src="preview.file"
                ></v-img>
              </v-col>

              <v-col cols="12">
                <v-file-input
                  solo
                  dark
                  @change="imageUpload"
                  @click:clear="clearImage"
                  v-model="page.image.value"
                  accept="image/*"
                  :label="page.image.label"
                  :error-messages="imageErrors"
                  show-size
                  prepend-icon=""
                  color="red darken-1"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip dark small label color="red">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>

              <v-col cols="12">
                <v-sheet rounded="lg" dark>
                  <v-container>
                    <v-row align="center" justify="center">
                      <v-col class="text-center" cols="12" md="5">
                        <InputFormAddProducts />
                      </v-col>

                      <v-col
                        class="py-0"
                        v-if="$vuetify.breakpoint.mobile"
                        cols="12"
                        md="2"
                      >
                        <v-divider dark></v-divider>
                      </v-col>

                      <v-divider v-else vertical dark></v-divider>

                      <v-col cols="12" md="5">
                        <h4>Items</h4>
                        <v-badge
                          class="mt-2"
                          :content="products.length"
                          :value="products.length"
                          color="red darken-1"
                          overlap
                        >
                          <v-icon dark large> mdi-package </v-icon>
                        </v-badge>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-sheet>
              </v-col>
              <v-col v-if="productsErrors.length > 0" cols="12">
                <p
                  class="ma-0 red--text"
                  v-for="(error, i) in productsErrors"
                  :key="i"
                >
                  {{ error }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="reset"> reset </v-btn>
          <v-btn color="success" @click="createPage"> add </v-btn>
        </v-card-actions>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import InputFormAddProducts from "../components/InputFormAddProducts";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "input-form-page",

  data: () => ({
    show: 0,

    preview: {
      name: "",
      file: null,
    },

    page: {
      image: {
        name: "",
        value: null,
        label: "Couples Image",
      },
      title: {
        value: "",
        label: "Wedding Title",
      },
      description: {
        value: "",
        label: "Wedding Description",
      },
      date: {
        value: new Date().toISOString().substr(0, 10),
        label: "Wedding Date",
      },
      products: [],
    },

    menu: false,

    imageErrors: [],

    productsErrors: [],
  }),

  components: {
    InputFormAddProducts,
  },

  validations: {
    page: {
      title: {
        value: {
          maxLength: maxLength(200),
          minLength: minLength(5),
        },
      },
      description: {
        value: {
          maxLength: maxLength(450),
          minLength: minLength(10),
        },
      },
      date: {
        value: {
          required,
        },
      },
    },
  },

  computed: {
    ...mapState({
      products: (state) => state.products,
      isValid: (state) => state.isValid.page,
    }),

    dateFormat() {
      return this.page.date.value
        ? moment(this.page.date.value).format("MMMM Do, YYYY")
        : "";
    },

    errors() {
      return {
        page: {
          title: (label) => {
            const errors = [];

            //if no errors return empty errors
            if (!this.$v.page.title.value.$dirty) {
              return errors;
            }

            //check if value is set if not push error for rule sets below
            !this.$v.page.title.value.maxLength &&
              errors.push(`${label} is too long!`);

            !this.$v.page.title.value.minLength &&
              errors.push(`${label} is too short!`);

            return errors;
          },
          description: (label) => {
            const errors = [];

            //if no errors return empty errors
            if (!this.$v.page.description.value.$dirty) {
              return errors;
            }

            //check if value is set if not push error for rule sets below
            !this.$v.page.description.value.maxLength &&
              errors.push(`${label} is too long!`);

            !this.$v.page.description.value.minLength &&
              errors.push(`${label} is too short!`);

            return errors;
          },
          date: (label) => {
            const errors = [];

            //if no errors return empty errors
            if (!this.$v.page.date.value.$dirty) {
              return errors;
            }

            //check if value is set if not push error for rule sets below
            !this.$v.page.date.value.required &&
              errors.push(`${label} is required!`);

            return errors;
          },
          image: (label) => {
            if (!this.page.image.value) {
              this.imageErrors.push(`${label} is required!`);
            }
          },
          products: () => {
            if (this.products.length <= 0) {
              this.productsErrors.push("Atleast 1 item is required!");
            }
          },
        },
      };
    },
  },

  methods: {
    clearImage() {
      this.imageErrors = [];
      this.preview.name = "";
      this.preview.file = null;
    },

    imageUpload(f) {
      //reset errors
      this.imageErrors = [];

      //in bytes
      const limitFileSize = 10000000;

      //check for file and file size
      if (!f) {
        return this.imageErrors.push("Please select a couples image!");
      }

      if (f.size > limitFileSize) {
        return this.imageErrors.push(
          "Image is too big please use another image!"
        );
      }

      const file = {
        name: f.name,
        type: f.type,
      };

      //set image name and value
      this.preview.name = file.name;

      //set preview of image using File api
      const reader = new FileReader();

      reader.readAsDataURL(f);

      reader.onload = () => {
        this.preview.file = reader.result;
      };
    },

    reset() {
      this.$v.$reset();

      this.page.title.value = "";
      this.page.description.value = "";
      this.page.date.value = "";
      this.preview.name = "";
      this.preview.file = null;
      this.page.image.name = "";
      this.page.image.value = null;
      this.page.products.value = [];

      //reset products in store
      this.$store.dispatch("resetProducts");
    },

    async createPage() {
      this.$v.$touch();

      //clear previous products errors
      this.productsErrors = [];

      // validate products and image
      this.errors.page.products();
      this.errors.page.image(this.page.image.label);

      //check if there are any validation errors
      this.$store.commit(
        "SET_VALIDATION_PAGE",
        !this.$v.$invalid &&
          this.imageErrors.length === 0 &&
          this.productsErrors.length === 0
      );

      if (this.isValid) {
        let page = {
          title: this.page.title.value,
          description: this.page.description.value,
          date: this.page.date.value,
          image: {
            name: this.preview.name,
            file: this.page.image.value,
          },
        };

        //store page info in store
        this.$store.dispatch("setPage", page);

        this.show = 1;
      }
    },
  },
};
</script>

<style>
</style>