import Vue from 'vue';
import Vuex from 'vuex';
import router from '../router';
import {
  couplesCollection,
  storage,
  addDoc,
  ref,
  uploadBytes,
  getDownloadURL
} from '../../firebase';

import axios from 'axios';

Vue.use(Vuex);

async function uploadProductImages(products, couplesRefPath) {
  return new Promise((resolve, reject) => {
    try {
      console.log(couplesRefPath);

      products.forEach(async (product) => {
        const productImageRef = ref(
          storage,
          `${couplesRefPath}/images/products/${product.id}/${product.image.name}`
        );

        await uploadBytes(productImageRef, product.image.file);
      });

      resolve('Done!');
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
}

/* eslint-disable no-new */
const store = new Vuex.Store({
  state: {
    isLoading: false,

    isValid: {
      page: false,
      form: false
    },

    snack: {
      value: false,
      message: ''
    },

    partner1: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: ''
    },

    partner2: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: ''
    },

    page: {
      title: '',
      description: '',
      date: null,
      image: {
        name: '',
        file: null
      }
    },

    products: [],

    user: null
  },

  mutations: {
    SET_SNACK: (state, payload) => {
      const { value, message } = payload;

      state.snack = {
        value,
        message
      };
    },

    SET_LOADING: (state, isLoading) => {
      state.isLoading = isLoading;
    },

    SET_VALIDATION_PAGE: (state, isValid) => {
      state.isValid.page = isValid;
    },

    SET_VALIDATION_FORM: (state, isValid) => {
      state.isValid.form = isValid;
    },

    SET_PRODUCTS: (state, products) => {
      state.products = products;
    },

    CLEAR_PRODUCTS: (state) => {
      state.products = [];
    },

    SET_PARTNER1: (state, partner1) => {
      state.partner1 = partner1;
    },

    SET_PARTNER2: (state, partner2) => {
      state.partner2 = partner2;
    },

    SET_PAGE: (state, payload) => {
      const { title, description, date, image } = payload;

      state.page = {
        title,
        description,
        date,
        image
      };
    },

    SET_USER: (state, user) => {
      state.user = user;
    }
  },

  actions: {
    setLoading({ commit }, isLoading) {
      commit('SET_LOADING', isLoading);
    },

    displayMessage({ commit }, payload) {
      commit('SET_SNACK', payload);
    },

    saveProducts({ commit }, products) {
      commit('SET_PRODUCTS', products);
    },

    setPartner1({ commit }, partner1) {
      commit('SET_PARTNER1', partner1);
    },

    setPartner2({ commit }, partner2) {
      commit('SET_PARTNER2', partner2);
    },

    setPage({ commit }, page) {
      commit('SET_PAGE', page);
    },

    setUser({ commit }, user) {
      commit('SET_USER', user);
    },

    resetProducts({ commit }) {
      commit('CLEAR_PRODUCTS');
    },

    async createPage({ commit, dispatch, state }) {
      //validate completion form logic object
      const validate = {
        partner1: {
          check: state.partner1 !== null ? true : false,
          name: 'Partner 1'
        },
        partner2: {
          check: state.partner2 !== null ? true : false,
          name: 'Partner 2'
        },
        page: {
          check: state.page !== null ? true : false,
          name: 'Page'
        }
      };

      //validate section form are filled
      commit(
        'SET_VALIDATION_FORM',
        validate.partner1.check &&
          validate.partner2.check &&
          validate.page.check
      );

      if (!state.isValid.form) {
        dispatch('displayMessage', {
          value: true,
          message: 'Please make sure the form is filled out!'
        });
      }

      const coupleSchema = {
        partner1: state.partner1,
        partner2: state.partner2,
        page: {
          title: state.page.title,
          description: state.page.description,
          date: state.page.date,
          image: {
            name: state.page.image.name
          },
          products: state.products.map((product) => {
            const copyProduct = {
              ...product,
              image: {
                name: product.image.name
              }
            };

            return copyProduct;
          })
        },
        customers: []
      };

      if (state.isValid.form) {
        //set load to true
        dispatch('setLoading', true);

        //reach out to backend to set coupleSchema
        try {
          //add couples data to couples collection
          const docRef = await addDoc(couplesCollection, coupleSchema);

          const baseFirebaseURL =
            process.env.VUE_APP_ENV === 'dev'
              ? `http://localhost:5001/${process.env.VUE_APP_PROJECT_ID}/us-central1`
              : `https://us-central1-${process.env.VUE_APP_PROJECT_ID}.cloudfunctions.net`;

          //set couples doc ID
          const couplesID = docRef.id;

          console.log(baseFirebaseURL);

          //uploadProductsJSON
          await axios.post(
            `${baseFirebaseURL}/uploadProductsJSON`,
            {
              couplesID: couplesID,
              products: coupleSchema.page.products,
              env: process.env.VUE_APP_ENV
            },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );

          //create storage refs
          let couplesRefPath = `${process.env.VUE_APP_ENV}/couples/${couplesID}`;

          console.log(couplesRefPath);

          if (process.env.VUE_APP_ENV === 'stage') {
            couplesRefPath = `${process.env.VUE_APP_ENV}/couples/${couplesID}`;
          }

          if (process.env.VUE_APP_ENV === 'prod') {
            couplesRefPath = `${process.env.VUE_APP_ENV}/couples/${couplesID}`;
          }

          const productRef = ref(
            storage,
            `${couplesRefPath}/products/products.json`
          );

          const imageRef = ref(
            storage,
            `${couplesRefPath}/images/page/${coupleSchema.page.image.name}`
          );

          console.log('Uploading page image...');

          //upload page image to storage
          await uploadBytes(imageRef, state.page.image.file);

          console.log(couplesRefPath);

          //upload product images
          await uploadProductImages(state.products, couplesRefPath);

          //get downloadURL from productRef
          const downloadURL = await getDownloadURL(productRef);

          //update product.json with url
          await axios.post(
            `${baseFirebaseURL}/updateProductsJSON`,
            {
              couplesID: couplesID,
              products: coupleSchema.page.products,
              url: downloadURL,
              env: process.env.VUE_APP_ENV
            },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );

          //after creating and updating product.json route to couples page!
          router.push({ path: `/couples-page/${couplesID}` });
        } catch (err) {
          console.log(err);
        }
      }
    }
  },

  getters: {},

  modules: {}
});

export default store;
