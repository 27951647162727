<template>
  <InputForm />
</template>

<script>
import InputForm from "../components/InputForm.vue";
import { signInAnonymously, auth } from "../../firebase";

export default {
  name: "Home",

  metaInfo: {
    title: "Wedding Registry",
  },

  components: {
    InputForm,
  },

  beforeRouteEnter(to, from, next) {
    signInAnonymously(auth);

    console.log("signed in");

    next();
  },
};
</script>
