<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    dark
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark large color="red darken-1" v-bind="attrs" v-on="on">
        Add Items
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="red darken-1">
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Add Wedding Items</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn light @click="saveProducts"> Save Items </v-btn>
      </v-toolbar>

      <v-container fluid>
        <!--Display Items Added in table-->
        <v-col cols="12">
          <v-simple-table v-show="this.products.length > 0" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ product.name.label }}
                  </th>
                  <th class="text-left">
                    {{ product.image.label }}
                  </th>
                  <th class="text-left">
                    {{ product.description.label }}
                  </th>
                  <th class="text-left">
                    {{ product.price.label }}
                  </th>
                  <th class="text-left">
                    {{ product.status.requested.label }}
                  </th>
                  <th class="text-left">Remove Item</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="product in products" :key="product.id">
                  <td>{{ product.name }}</td>
                  <td>
                    <v-img
                      class="my-2"
                      width="50px"
                      :src="product.image.preview"
                    ></v-img>
                  </td>
                  <td>{{ product.description }}</td>
                  <td>${{ parseFloat(product.price).toFixed(2) }}</td>
                  <td>{{ product.status.requested }}</td>
                  <td class="text-center">
                    <v-btn
                      @click="deleteProduct(product.id)"
                      text
                      fab
                      small
                      color="red darken-1"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>

        <v-divider></v-divider>

        <v-row>
          <v-col cols="12">
            <v-form ref="item-form">
              <v-container fluid>
                <v-row align="center" justify="center">
                  <v-col cols="12">
                    <v-text-field
                      solo
                      light
                      color="red darken-1"
                      v-model.lazy="product.name.value"
                      :label="product.name.label"
                      :error-messages="errors.product.name(product.name.label)"
                      :counter="50"
                      @input="$v.product.name.value.$touch"
                      @blur="$v.product.name.value.$touch"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-file-input
                      solo
                      light
                      @change="imageUpload"
                      @click:clear="clearImage"
                      color="red darken-1"
                      v-model="product.image.value"
                      accept="image/*"
                      :label="product.image.label"
                      :error-messages="imageErrors"
                      show-size
                      prepend-icon=""
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip dark small label color="red">
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>

                  <v-col cols="12">
                    <v-textarea
                      solo
                      light
                      color="red darken-1"
                      v-model="product.description.value"
                      :label="product.description.label"
                      :error-messages="
                        errors.product.description(product.description.label)
                      "
                      :counter="450"
                      @input="$v.product.description.value.$touch"
                      @blur="$v.product.description.value.$touch"
                      required
                    ></v-textarea>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      solo
                      light
                      color="red darken-1"
                      prefix="$"
                      v-model="product.price.value"
                      v-money="money"
                      :label="product.price.label"
                      :error-messages="
                        errors.product.price(product.price.label)
                      "
                      @keydown="
                        $event.key === '-' ? $event.preventDefault() : null
                      "
                      @input="$v.product.price.value.$touch"
                      @blur="$v.product.price.value.$touch"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12 text-left">
                    <v-text-field
                      solo
                      light
                      color="red darken-1"
                      v-model="product.status.requested.value"
                      :label="product.status.requested.label"
                      :error-messages="
                        errors.product.requested(product.status.requested.label)
                      "
                      @input="$v.product.status.requested.value.$touch"
                      @blur="$v.product.status.requested.value.$touch"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-btn class="mx-auto" large color="success" @click="addProduct">
            Add Item
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import {
  required,
  minLength,
  maxLength,
  minValue,
  integer,
} from "vuelidate/lib/validators";
import { VMoney } from "v-money-no-negative";

export default {
  name: "add-products",

  directives: { money: VMoney },

  data: () => ({
    dialog: false,

    preview: {
      name: "",
      file: null,
    },

    products: [],

    imageErrors: [],

    money: {
      decimal: ".",
      thousands: ",",
      precision: 2,
      masked: false,
    },

    product: {
      id: {
        label: "ID",
        value: "",
      },
      name: {
        label: "Item Name",
        value: "",
      },
      image: {
        label: "Item Image",
        name: "",
        value: null,
      },
      price: {
        label: "Item Price",
        value: 0,
      },
      description: {
        label: "Item Description",
        value: "",
      },
      status: {
        requested: {
          label: "Item Request",
          value: 1,
        },
      },
    },
  }),

  validations: {
    product: {
      name: {
        value: {
          required,
          maxLength: maxLength(50),
          minLength: minLength(2),
        },
      },
      description: {
        value: {
          required,
          maxLength: maxLength(450),
          minLength: minLength(10),
        },
      },
      price: {
        value: {
          required,
          minValue: minValue(0),
        },
      },
      status: {
        requested: {
          value: {
            required,
            integer,
            minValue: minValue(1),
          },
        },
      },
    },
  },

  computed: {
    errors() {
      return {
        product: {
          name: (label) => {
            const errors = [];

            //if no errors return empty errors
            if (!this.$v.product.name.value.$dirty) {
              return errors;
            }

            //check if value is set if not push error for rule sets below
            !this.$v.product.name.value.required &&
              errors.push(`${label} is required!`);

            !this.$v.product.name.value.maxLength &&
              errors.push(`${label} is too long!`);

            !this.$v.product.name.value.minLength &&
              errors.push(`${label} is too short!`);

            return errors;
          },
          description: (label) => {
            const errors = [];

            //if no errors return empty errors
            if (!this.$v.product.description.value.$dirty) {
              return errors;
            }

            //check if value is set if not push error for rule sets below
            !this.$v.product.description.value.required &&
              errors.push(`${label} is required!`);

            !this.$v.product.description.value.maxLength &&
              errors.push(`${label} is too long!`);

            !this.$v.product.description.value.minLength &&
              errors.push(`${label} is too short!`);

            return errors;
          },
          price: (label) => {
            const errors = [];

            //if no errors return empty errors
            if (!this.$v.product.price.value.$dirty) {
              return errors;
            }

            //check if value is set if not push error for rule sets below
            !this.$v.product.price.value.required &&
              errors.push(`${label} is required!`);

            !this.$v.product.price.value.minValue &&
              errors.push(`${label} cannot be a negative number!`);

            return errors;
          },
          requested: (label) => {
            const errors = [];

            //if no errors return empty errors
            if (!this.$v.product.status.requested.value.$dirty) {
              return errors;
            }

            //check if value is set if not push error for rule sets below
            !this.$v.product.status.requested.value.required &&
              errors.push(`${label} is required!`);

            !this.$v.product.status.requested.value.minValue &&
              errors.push(`You need to request atleast 1 item!`);

            !this.$v.product.status.requested.value.integer &&
              errors.push(`${label} has to be a number!`);

            return errors;
          },
          image: (label) => {
            if (!this.product.image.value) {
              this.imageErrors.push(`${label} is required!`);
            }
          },
        },
      };
    },
  },

  methods: {
    clearImage() {
      this.imageErrors = [];
      this.preview.name = "";
      this.preview.file = null;
    },

    imageUpload(f) {
      //reset errors
      this.imageErrors = [];

      //in bytes
      const limitFileSize = 10000000;

      if (!f) {
        return this.imageErrors.push("Please select a couples image!");
      }

      if (f.size > limitFileSize) {
        return this.imageErrors.push(
          "Image is too big please use another image!"
        );
      }

      const file = {
        name: f.name,
        type: f.type,
      };

      //set image name and value
      this.preview.name = file.name;

      //set preview of image using File api
      const reader = new FileReader();

      reader.readAsDataURL(f);

      reader.onload = () => {
        this.preview.file = reader.result;
      };
    },

    deleteProduct(productID) {
      //get product index in products
      const productIndex = this.products.findIndex((product) => {
        return product.id === productID;
      });

      //delete product from index
      this.products.splice(productIndex, 1);
    },

    addProduct() {
      //make sure user touched all inputs or return error
      this.$v.$touch();

      //set generate uuid for item
      this.product.id.value = uuidv4();

      // validate products and image
      this.errors.product.image(this.product.image.label);

      //check if there is any invalid inputs
      const isValid = !this.$v.$invalid && this.imageErrors.length === 0;

      if (isValid) {
        const product = {
          id: this.product.id.value,
          name: this.product.name.value,
          description: this.product.description.value,
          price: parseFloat(this.product.price.value).toFixed(2),
          image: {
            name: this.preview.name,
            preview: this.preview.file,
            file: this.product.image.value,
          },
          status: {
            requested: parseInt(this.product.status.requested.value),
          },
        };

        this.products.push(product);

        //reset touches
        this.$v.$reset();

        //clear fields when added
        this.product.id.value = "";
        this.product.name.value = "";
        this.preview.name = "";
        this.preview.file = null;
        this.product.image.name = "";
        this.product.image.value = null;
        this.product.description.value = "";
        this.product.price.value = "";
        this.product.status.requested.value = "";
      }
    },

    saveProducts() {
      //filter products
      const filteredProducts = this.products.map((product) => {
        const copyProduct = {
          ...product,
          image: {
            name: product.image.name,
            file: product.image.file,
          },
        };

        return copyProduct;
      });

      //add product to store
      this.$store.dispatch("saveProducts", filteredProducts);

      this.close();
    },

    close() {
      //close expansion panel
      this.dialog = false;

      //reset touches
      this.$v.$reset();

      //clear fields when added
      this.product.id.value = "";
      this.product.name.value = "";
      this.product.image.name = "";
      this.product.image.value = null;
      this.product.description.value = "";
      this.product.price.value = "";
      this.product.status.requested.value = "";
    },
  },
};
</script>

<style>
</style>