import { initializeApp } from 'firebase/app';

import {
  getFirestore,
  connectFirestoreEmulator,
  collection,
  doc,
  getDoc,
  addDoc
} from 'firebase/firestore';

import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

import {
  getStorage,
  connectStorageEmulator,
  ref,
  uploadBytes,
  getDownloadURL
} from 'firebase/storage';
import { getAuth, connectAuthEmulator, signInAnonymously } from 'firebase/auth';

//init firebase app
const firebaseApp = initializeApp({
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID
});

let db = getFirestore(firebaseApp);
let functions = getFunctions(firebaseApp);
let storage = getStorage(firebaseApp);
let auth = getAuth(firebaseApp);

//if env is set to development use emulators
if (process.env.NODE_ENV === 'dev') {
  storage = getStorage(firebaseApp, 'gs://default-bucket/');
  connectFirestoreEmulator(db, 'localhost', 8081);
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectStorageEmulator(storage, 'localhost', 9199);
}

//set collections
const couplesCollection = collection(db, 'couples');
const customersCollection = collection(db, 'customers');
const ordersCollection = collection(db, 'orders');

//export libs
export {
  db,
  functions,
  storage,
  auth,
  couplesCollection,
  customersCollection,
  ordersCollection,
  doc,
  getDoc,
  addDoc,
  signInAnonymously,
  ref,
  uploadBytes,
  getDownloadURL
};
