<template>
  <v-expansion-panels popout v-model="show">
    <v-expansion-panel>
      <v-expansion-panel-header
        v-slot
        :disabled="isValid"
        :expand-icon="isValid ? 'mdi-check' : 'mdi-chevron-down'"
      >
        <v-row no-gutters>
          <v-col class="left-text" cols="12">
            <h2>
              <v-icon color="black" large left> mdi-account-circle </v-icon>
              Partner 2
            </h2>
          </v-col>
        </v-row>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <v-form>
          <v-container fluid>
            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-text-field
                  solo
                  dark
                  v-model="partner2.firstName.value"
                  :label="partner2.firstName.label"
                  :error-messages="
                    errors.partner2.firstName(partner2.firstName.label)
                  "
                  :counter="50"
                  @input="$v.partner2.firstName.value.$touch"
                  @blur="$v.partner2.firstName.value.$touch"
                  required
                >
                </v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  solo
                  dark
                  v-model="partner2.lastName.value"
                  :label="partner2.lastName.label"
                  :error-messages="
                    errors.partner2.lastName(partner2.lastName.label)
                  "
                  :counter="50"
                  @input="$v.partner2.lastName.value.$touch"
                  @blur="$v.partner2.lastName.value.$touch"
                  required
                >
                </v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  solo
                  dark
                  v-model="partner2.email.value"
                  :label="partner2.email.label"
                  :error-messages="errors.partner2.email(partner2.email.label)"
                  @input="$v.partner2.email.value.$touch"
                  @blur="$v.partner2.email.value.$touch"
                  required
                >
                </v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  solo
                  dark
                  v-model="partner2.phoneNumber.value"
                  :label="partner2.phoneNumber.label"
                  :error-messages="
                    errors.partner2.phoneNumber(partner2.phoneNumber.label)
                  "
                  @input="$v.partner2.phoneNumber.value.$touch"
                  @blur="$v.partner2.phoneNumber.value.$touch"
                  required
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="reset"> reset </v-btn>
          <v-btn color="success" @click="createPartner2"> add </v-btn>
        </v-card-actions>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";

export default {
  name: "input-form-partner-2",

  data: () => ({
    isValid: false,

    show: 0,

    partner2: {
      firstName: {
        value: "",
        label: "First Name",
      },
      lastName: {
        value: "",
        label: "Last Name",
      },
      phoneNumber: {
        value: "",
        label: "Phone Number",
      },
      email: {
        value: "",
        label: "Email",
      },
    },
  }),

  validations: {
    partner2: {
      firstName: {
        value: {
          required,
          maxLength: maxLength(50),
          minLength: minLength(2),
        },
      },
      lastName: {
        value: {
          required,
          maxLength: maxLength(50),
          minLength: minLength(2),
        },
      },
      email: {
        value: {
          required,
          email,
        },
      },
      phoneNumber: {
        value: {
          required,
          minLength: minLength(1),
        },
      },
    },
  },

  computed: {
    errors() {
      return {
        partner2: {
          firstName: (label) => {
            const errors = [];
            //if no errors return empty errors
            if (!this.$v.partner2.firstName.value.$dirty) {
              return errors;
            }
            //check if value is set if not push error for rule sets below
            !this.$v.partner2.firstName.value.required &&
              errors.push(`${label} is required!`);
            !this.$v.partner2.firstName.value.maxLength &&
              errors.push(`${label} is too long!`);
            !this.$v.partner2.firstName.value.minLength &&
              errors.push(`${label} is too short!`);
            return errors;
          },
          lastName: (label) => {
            const errors = [];
            //if no errors return empty errors
            if (!this.$v.partner2.lastName.value.$dirty) {
              return errors;
            }
            //check if value is set if not push error for rule sets below
            !this.$v.partner2.lastName.value.required &&
              errors.push(`${label} is required!`);
            !this.$v.partner2.lastName.value.maxLength &&
              errors.push(`${label} is too long!`);
            !this.$v.partner2.lastName.value.minLength &&
              errors.push(`${label} is too short!`);
            return errors;
          },
          email: (label) => {
            const errors = [];
            //if no errors return empty errors
            if (!this.$v.partner2.email.value.$dirty) {
              return errors;
            }
            //check if value is set if not push error for rule sets below
            !this.$v.partner2.email.value.required &&
              errors.push(`${label} is required!`);
            !this.$v.partner2.email.value.email &&
              errors.push(`${label} is not a valid!`);
            return errors;
          },
          phoneNumber: (label) => {
            const errors = [];
            //if no errors return empty errors
            if (!this.$v.partner2.phoneNumber.value.$dirty) {
              return errors;
            }
            //check if value is set if not push error for rule sets below
            !this.$v.partner2.phoneNumber.value.required &&
              errors.push(`${label} is required!`);
            return errors;
          },
        },
      };
    },
  },

  methods: {
    reset() {
      this.$v.$reset();

      this.partner2.firstName.value = "";
      this.partner2.lastName.value = "";
      this.partner2.email.value = "";
      this.partner2.phoneNumber.value = "";
    },

    createPartner2() {
      this.$v.$touch();

      this.isValid = !this.$v.$invalid;

      if (this.isValid) {
        let partner2 = {
          firstName: this.partner2.firstName.value,
          lastName: this.partner2.lastName.value,
          email: this.partner2.email.value,
          phoneNumber: this.partner2.phoneNumber.value,
        };

        //store partner2 info in store
        this.$store.dispatch("setPartner2", partner2);

        this.show = 1;

        this.reset();
      }
    },
  },
};
</script>

<style>
</style>