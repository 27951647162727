<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <InputFormPartner1 />
      </v-col>

      <v-col cols="12">
        <InputFormPartner2 />
      </v-col>

      <v-col cols="12">
        <InputFormPage />
      </v-col>

      <v-col cols="12">
        <v-btn
          dark
          :loading="isLoading"
          color="red darken-1"
          @click="createPage"
          >Create Page</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InputFormPartner1 from "../components/InputFormPartner1";
import InputFormPartner2 from "../components/InputFormPartner2";
import InputFormPage from "../components/InputFormPage";
import { mapState } from "vuex";

export default {
  name: "input-form",

  components: {
    InputFormPartner1,
    InputFormPartner2,
    InputFormPage,
  },

  computed: {
    ...mapState(["isLoading"]),
  },

  methods: {
    async createPage() {
      this.$store.dispatch("createPage");
    },
  },
};
</script>




