import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import store from './store';

import VueMeta from 'vue-meta';
import Vuelidate from 'vuelidate';

Vue.use(VueMeta);
Vue.use(Vuelidate);

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App)
}).$mount('#app');
