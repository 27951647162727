import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

//import router components
const couplesPage = () => import('../views/CouplesPage');
const pageNotFoundPage = () => import('../views/404.vue');

const routes = [
  {
    path: '/wedding-registry',
    name: 'Home',
    component: Home
  },
  {
    path: '/couples-page/:id',
    name: 'Couples Page',
    component: couplesPage
  },
  {
    path: '*',
    name: 'Page Not Found',
    component: pageNotFoundPage
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
